const axios = require("../config/axios.config.js").default;
const pageParams = "expert/";
function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
function getContent(avatar) {
  var block = avatar.split(";");
  // Get the content type of the image
  var contentType = block[0].split(":")[1]; // In this case "image/gif"
  // get the real base64 content of the file
  var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
  var blob = b64toBlob(realData, contentType);
  return blob;
}
export default {
  namespaced: true,
  state: () => ({
    list: [],
    history: {
      data: [],
      total: 1
    },
    statistical: {},
    historyCommunity: {
      data: [],
      total: 1
    },
    infoExpert: {
      infoExpert: {}
    },
    community: {
      data: [],
      last_page: 1,
      perpage: 6,
      total: 3
    }
  }),

  getters: {
    List: state => {
      return state.list;
    },
    History: state => {
      return state.history;
    },
    HistoryCommunity: state => {
      return state.historyCommunity;
    },
    Statistical: state => {
      return state.statistical;
    },
    InfoExpert: state => {
      return state.infoExpert;
    },
    Community: state => {
      return state.community.Expert;
    }
  },

  actions: {
    req_postFollow: function({ commit }, input) {
      axios.post(pageParams + "follow", input).then(function(response) {
        if (response) {
          commit("FOLLOW_SUCCESS");
        }
      });
    },
    req_postCancel: function({ commit }, input) {
      axios.post(pageParams + "cancel", input).then(function(response) {
        if (response) {
          commit("CANCEL_SUCCESS");
        }
      });
    },
    req_getFollowList: function({ commit }, input) {
      axios
        .get(pageParams + "list", {
          params: input
        })
        .then(function(response) {
          if (response) {
            commit("SET_LIST_FOLLOW", response);
          }
        });
    },
    // /expert/list-community
    req_getExpertCommunity: function({ commit }, input) {
      axios
        .get(pageParams + "list-community", {
          params: input
        })
        .then(function(response) {
          if (response) {
            commit("SET_LIST_COMMUNITY", response);
          }
        });
    },
    req_getHistoryList: function({ commit }, input) {
      axios
        .get(pageParams + "history", {
          params: input
        })
        .then(function(response) {
          if (response) {
            commit("SET_HISTORY_LIST", response);
          }
        });
    },
    req_getHistoryCommunity: function({ commit }, input) {
      axios
        .get(pageParams + "history-profit", {
          params: input
        })
        .then(function(response) {
          if (response) {
            commit("SET_HISTORY_COMMUNITY", response);
          }
        });
    },
    req_postWithdrawProfit: function({ commit }, input) {
      axios.post(pageParams + "withdraw-profit", input).then(response => {
        if (response) {
          commit("WITHDRAW_PROFIT_SUCCESS", response);
        }
      });
    },
    req_postResgisterExpert: function({ commit }, input) {
      axios.post(pageParams + "", input).then(response => {
        if (response) {
          commit("REGISTER_SUCCESS", response);
        }
      });
    },
    req_getInfoExpert: function({ commit }) {
      axios.get(pageParams + "condition").then(function(response) {
        if (response) {
          commit("SET_INFO_EXPERT", response);
        }
      });
    },
    req_postRegiserExpert: function({ commit, dispatch }, input) {
      let formData = new FormData();
      formData.append("avatar", getContent(input.avatar));
      formData.append("timeFrom", parseInt(input.timeFrom));
      formData.append("percentCopyTrade", input.percent);
      formData.append("name", input.name);
      axios
        .post(pageParams + "register-expert", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function(response) {
          if (response !== null && response !== undefined) {
            commit("POST_REGISTER_SUCCESS");
            dispatch("req_getInfoExpert");
          }
        });
    },
    req_postChangeTime: function({ dispatch, commit }, input) {
      axios
        .post(pageParams + "change-time", {
          timeFrom: parseInt(input.time)
        })
        .then(function(response) {
          if (response) {
            dispatch("req_getInfoExpert");
            commit("CHANGE_SUCCESS");
          }
        });
    }
  },

  mutations: {
    SET_LIST_FOLLOW: function(state, data) {
      state.list = data;
      state.statistical.pending = data.BalancePending;
      state.statistical.profit = data.balanceProfit;
      state.statistical.total = data.profitAllExpert;
    },
    CANCEL_SUCCESS: function() {
      //   this.dispatch("copytrade/req_getFollowList");
      this.dispatch("userinfo/req_getBalance");
    },
    FOLLOW_SUCCESS: function() {
      //   this.dispatch("copytrade/req_getFollowList");
      this.dispatch("userinfo/req_getBalance");
    },
    SET_HISTORY_LIST: function(state, data) {
      state.history.total = data.last_page;
      state.history.data = data.data;
    },
    SET_STATISTICS: function(state, data) {
      state.statistical = data.statistical;
    },
    SET_HISTORY_COMMUNITY: function(state, data) {
      state.historyCommunity.data = data.data;
      state.historyCommunity.total = data.last_page;
    },
    WITHDRAW_PROFIT_SUCCESS: function(state, data) {
      if (data.MainBalance !== undefined && data.MainBalance !== null) {
        this.commit("userinfo/setBalace", {
          main: true,
          balance: parseFloat(data.MainBalance)
        });
      }
      for (const expert of state.list.Expert) {
        if (expert.id == data.expert) {
          expert.user_profit = data.profitBalance;
        }
      }
    },
    REGISTER_SUCCESS: function() {},
    SET_INFO_EXPERT(state, data) {
      state.infoExpert = data;
    },
    POST_REGISTER_SUCCESS() {},
    CHANGE_SUCCESS() {},
    SET_LIST_COMMUNITY(state, data) {
      state.community = data;
    }
  }
};
