const axios = require("../config/axios.config.js").default;
const pageParams = 'statistical/';
export default {
    namespaced: true,
    state: () => ({
        statistical_trade: {
            list: [],
            total: {
                total: 0,
                total_win: 0,
                total_draw: 0,
                total_lost: 0,
                total_numwin: 0,
                total_numlost: 0,
                total_numdraw: 0,
            },
        },
        statistical_money: {
            list: [],
            total: {
                deposit: 0,
                withdraw: 0,
                deposit_to_game: 0,
                withdraw_from_game: 0,
                transfer_to: 0,
                transfer_from: 0,
                main: 0,
                live: 0
            },
        }
    }),

    getters: {
        StatisticalTrade: (state) => { return state.statistical_trade },
        StatisticalMoney: (state) => { return state.statistical_money },
    },

    actions: {
        req_getStatisticsTrade: async function ({ commit }, input) {
            axios.get(pageParams + "game", {
                params: input
            }).then((response) => {
                if (response) {
                    commit("SET_STATISTICS_TRADE", response);
                }
            });
        },
        req_getStatisticsMoney: async function ({ commit }, input) {
            axios.get(pageParams + "money", {
                params: input
            }).then((response) => {
                if (response) {
                    commit("SET_STATISTICS_MONEY", response);
                }
            });
        }
    },

    mutations: {
        SET_STATISTICS_TRADE: function (state, data) {
            state.statistical_trade.total = {
                total: 0,
                total_win: 0,
                total_draw: 0,
                total_lost: 0,
                total_numwin: 0,
                total_numlost: 0,
                total_numdraw: 0,
            }

            state.statistical_trade.list = data;

            for (let i = 0; i < data.length; i++) {
                state.statistical_trade.total.total += parseFloat(data[i].statistical_TotalBet);
                state.statistical_trade.total.total_win += parseFloat(data[i].statistical_TotalWin);
                state.statistical_trade.total.total_draw += parseFloat(data[i].statistical_TotalDraw);
                state.statistical_trade.total.total_lost += parseFloat(data[i].statistical_TotalLost);
                state.statistical_trade.total.total_numwin += parseFloat(data[i].statistical_Win);
                state.statistical_trade.total.total_numlost += parseFloat(data[i].statistical_Loss);
                state.statistical_trade.total.total_numdraw += parseFloat(data[i].statistical_Refurn);

            }
        },
        SET_STATISTICS_MONEY: function (state, data) {
            state.statistical_money.total = {
                deposit: 0,
                withdraw: 0,
                deposit_to_game: 0,
                withdraw_from_game: 0,
                transfer_to: 0,
                transfer_from: 0,
                main: 0,
                live: 0
            }

            state.statistical_money.list = data;

            for (let i = 0; i < data.length; i++) {
                state.statistical_money.total.deposit += parseFloat(data[i].Deposit);
                state.statistical_money.total.withdraw += parseFloat(data[i].Withdraw);
                state.statistical_money.total.deposit_to_game += parseFloat(data[i].DepositToGame);
                state.statistical_money.total.withdraw_from_game += parseFloat(data[i].WithdrawFromGame);
                state.statistical_money.total.transfer_to += parseFloat(data[i].TransferTo);
                state.statistical_money.total.transfer_from += parseFloat(data[i].TransferFrom);
                state.statistical_money.total.main += parseFloat(data[i].main);
                state.statistical_money.total.live += parseFloat(data[i].trade);

            }
        }
    }
};
