const axios = require("../config/axios.config.js").default;
export default {
    namespaced: true,
    state: () => ({
        agency: {},
        listAgency: '',
        member: {
            total: 0,
            data: {}
        }
    }),

    getters: {
        Agency: (state) => { return state.agency },
        ListAgency: (state) => { return state.listAgency },
        Member: (state) => { return state.member }
    },

    actions: {
        postBuyAgency: async function ({ commit, dispatch }, input) {
            axios.post("agency/buy", input).then(function (response) {
                if (response) {
                    commit("BuyAgencySuccess")
                    dispatch("req_getAgency");
                    commit("userinfo/setBalace", {
                        main: true,
                        balance: response.mainBalance,
                    }, { root: true });
                    commit("userinfo/setBalace", {
                        live: true,
                        balance: response.tradeBalance,
                    }, { root: true });
                }
            })
        },
        req_getAgency: async function ({ commit }) {
            axios.get("agency").then(function (response) {
                commit("GetAgencySuccess", response)
            })
        },
        req_getMember: function ({ commit }, input) {
            axios.get("agency/member-list", {
                params: input
            }).then(function (response) {
                if (response) {
                    commit("SET_MEMBER", response.Member);
                }
            })
        }
    },

    mutations: {

        BuyAgencySuccess: function () { },
        GetAgencySuccess: function (state, data) {
            state.agency = data;
        },
        SET_MEMBER: function (state, data) {
            state.member.total = data.last_page;
            state.member.data = data.data;
        }
    }
};